import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import AboutTab from './components/AboutTab.vue';
import EducationTab from './components/EducationTab.vue';
import ExperienceTab from './components/ExperienceTab.vue';
import ProjectsTab from './components/ProjectsTab.vue';
import EarlyPage from './components/EarlyPage.vue';
import TrafficTab from './components/TrafficTab.vue';
import ContactTab from './components/ContactTab.vue';
import IndexPage from './components/IndexPage.vue';
import VueGtag from "vue-gtag-next";

// Define your routes with meta titles
const routes = [
  { path: '/about', component: AboutTab, meta: { title: 'About - H.W.' } },
  { path: '/education', component: EducationTab, meta: { title: 'Education - H.W.' } },
  { path: '/experience', component: ExperienceTab, meta: { title: 'Experience - H.W.' } },
  { path: '/projects', component: ProjectsTab, meta: { title: 'Projects - H.W.' } },
  { path: '/early', component: EarlyPage, meta: { title: 'Early CS Adventure - H.W.' } },
  { path: '/traffic', component: TrafficTab, meta: { title: 'Traffic - H.W.' } },
  { path: '/contact', component: ContactTab, meta: { title: 'Contact - H.W.' } },
  { path: '/home', component: IndexPage, meta: { title: 'Home - H.W.' } },
  { path: '/', component: IndexPage, meta: { title: 'H.W. | An enthusiastic explorer' } }
];

// Create the router instance
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Create the Vue app instance
const app = createApp(App);

// Use the Google Analytics plugin with your Tracking ID
app.use(VueGtag, {
  property: {
    id: "G-WED90XV0C9"  // Your Google Analytics Tracking ID
  }
}, router);

// After each route change, update the document title and trigger a Google Analytics page view
router.afterEach((to) => {
  const pageTitle = to.meta.title || 'H.W. | An enthusiastic explorer';
  const pagePath = to.fullPath;

  document.title = pageTitle;
  
  // // Log the title and path being sent to Google Analytics
  // console.log('Sending to Google Analytics:');
  // console.log('Page Title:', pageTitle);
  // console.log('Page Path:', pagePath);
  
  // Trigger Google Analytics page view using vue-gtag-next
  app.config.globalProperties.$gtag.pageview({
    page_path: pagePath,
    page_title: pageTitle,
  });
});

// Use the router
app.use(router);

// Mount the app
app.mount('#app');