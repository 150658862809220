<template>
    <div>
      <h1>
        Let's Chat
      </h1>
      <article>
        <p>If you have any questions, want to collaborate, or just want to chat, feel free to get in touch! You can reach me at:</p>
        <p><i class="fa fa-user" style="width:2em" aria-hidden="true"></i> Personal Email: <a href="mailto:whenry6688@gmail.com">whenry6688@gmail.com</a> for personal messages.</p>
        <p><i class="fa fa-graduation-cap" style="width:2em" aria-hidden="true"></i> School Email: <a href="mailto:henry.why@connect.hku.hk">henry.why@connect.hku.hk</a> for academic inquiries.</p>
        <p><i class="fa fa-briefcase" style="width:2em" aria-hidden="true"></i> Work Email: <a href="mailto:henry.wang@kiwiviewintl.co.nz">henry.wang@kiwiviewintl.co.nz</a> for cooperation, inquiries, or any matters related to <em>Kiwiview International Limited</em>.</p>
        <p><a><i class="fa fa-phone" style="width:2em" aria-hidden="true"></i></a> Mobile: +852 51305468</p>
        <br>
        <h2>Visitor Map</h2>
        <div class='visitormap'></div>
        <br>
      </article>
    </div>
  </template>

<script>
export default {
  name: 'ContactTab',
  data() {
      return {
          scriptModule: null,
      };
  },
  async mounted() {
      // Dynamically import the script when the component is mounted
      this.scriptModule = await import('../assets/js/loadmap.js');
      if (this.scriptModule && this.scriptModule.initScript) {
          this.scriptModule.initScript();
      }
  },
  unmounted() {
      // Clean up the script when the component is unmounted
      if (this.scriptModule && this.scriptModule.cleanupScript) {
          this.scriptModule.cleanupScript();
      }
      this.scriptModule = null; // Optionally, clear the reference to the module
  },
}
</script>