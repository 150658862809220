<template>
    <div>
        <h1></h1>
        <article>
        <div class="image-left container" style="margin: auto;">
            <video class="homevideo1" id="videoElement" style="opacity: 0;" muted autoplay loop playsinline>
                <source src="/assets/vid/home1.mp4" type="video/mp4">
                Your browser does not support this website.
            </video>
            <img id="imageElement" src="/assets/img/home1.jpg" alt="Travel" class="fallback-image" style="display: none; opacity: 0;">
            <div class="transition-overlay" id="transitionOverlay"></div>
            <div class="description-overlay" id="descriptionOverlay">
                <div class="description-content" id="descriptionContent"></div>
            </div>
            <div class="welcome-text">
                <h1 id="welcomeTitle" style="color: transparent;">WELC<span id="compassContainer"><i class="far fa-compass" id="compassIcon"></i></span>ME</h1>
                <h2 id="welcomeSubtitle">Hello! I'm Han-yu (Henry), a junior at HKU, majoring in AI. I love to explore new places and code apps. I'm excited to have you here and ready to share my journey with you!</h2>
            </div>
        </div>

        <div id="overlay"></div>

        <br>

        <h3 id="welcomeQuote" style="color: transparent;">Life is an endless adventure<br>into the unknown</h3>
        </article>
    </div>
</template>
  
<script>
    export default {
        name: 'IndexPage',
        data() {
            return {
                scriptModule: null,
            };
        },
        async mounted() {
            // Dynamically import the script when the component is mounted
            this.scriptModule = await import('../assets/js/home.js');
            if (this.scriptModule && this.scriptModule.initScript) {
                this.scriptModule.initScript();
            }
        },
        unmounted() {
            // Clean up the script when the component is unmounted
            if (this.scriptModule && this.scriptModule.cleanupScript) {
                this.scriptModule.cleanupScript();
            }
            this.scriptModule = null; // Optionally, clear the reference to the module
        },
    }
</script>
 
<style scoped>
    html, body {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none;    /* Firefox */
        -ms-user-select: none;     /* Internet Explorer/Edge */
        user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
    }

    #videoElement.homevideo1, #imageElement{
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 288 512" fill="%23FFFFFF"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M216 464h-40V346.8c68.5-15.9 118.1-79.9 111.4-154.2l-16-178.1C270.7 6.3 263.9 0 255.7 0H32.3c-8.2 0-15 6.3-15.7 14.6L.6 192.7C-6.1 266.9 43.5 330.9 112 346.8V464H72c-22.1 0-40 17.9-40 40 0 4.4 3.6 8 8 8h208c4.4 0 8-3.6 8-8 0-22.1-17.9-40-40-40zM61.8 48h164.5l7.2 80H54.6l7.2-80z"/></svg>') 32 32, auto;
    }

    #videoElement.homevideo2 {
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 512 512" fill="%23FFFFFF"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M288 167.2v-28.1c-28.2-36.3-47.1-79.3-54.1-125.2-2.1-13.5-19-18.8-27.8-8.3-21.1 24.9-37.7 54.1-48.9 86.5 34.2 38.3 80 64.6 130.8 75.1zM400 64c-44.2 0-80 35.9-80 80.1v59.4C215.6 197.3 127 133 87 41.8c-5.5-12.5-23.2-13.2-29-.9C41.4 76 32 115.2 32 156.6c0 70.8 34.1 136.9 85.1 185.9 13.2 12.7 26.1 23.2 38.9 32.8l-143.9 36C1.4 414-3.4 426.4 2.6 435.7 20 462.6 63 508.2 155.8 512c8 .3 16-2.6 22.1-7.9l65.2-56.1H320c88.4 0 160-71.5 160-159.9V128l32-64H400zm0 96.1c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z"/></svg>') 32 32, auto;
    }

    #videoElement.homevideo3 {
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 512 512" fill="%23FFFFFF"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M290.6 192c-20.2 0-106.8 2-162.6 86V192c0-52.9-43.1-96-96-96-17.7 0-32 14.3-32 32s14.3 32 32 32c17.6 0 32 14.4 32 32v256c0 35.3 28.7 64 64 64h176c8.8 0 16-7.2 16-16v-16c0-17.7-14.3-32-32-32h-32l128-96v144c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V289.9c-10.3 2.7-20.9 4.5-32 4.5-61.8 0-113.5-44.1-125.4-102.4zM448 96h-64l-64-64v134.4c0 53 43 96 96 96s96-43 96-96V32l-64 64zm-72 80c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z"/></svg>') 32 32, auto;
    }

    #videoElement, #imageElement {
        z-index: 2000;
    }

    video, .fallback-image {
        max-width: 45%;
        height: auto;
    }

    video, #imageElement{
        filter: grayscale(85%);
        transition: opacity 3s ease-in-out, filter 1s ease-in-out;
    }

    .transition-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 45%;
        height: 100%;
        opacity: 0;
        background: #AFB4B9;
        pointer-events: none;
        z-index: 3000;
    }

    .description-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 45%;
        height: 100%;
        opacity: 0;
        background: #000000;
        pointer-events: none;
        z-index: 4000;
        transition: opacity 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .description-content {
        text-align: center; /* Center text inside the content */
        color: white; /* Set text color to white for visibility */
        margin: 15%;
        font-size: 25px;
        line-height: 45px;
    }

    .description-content p {
        font-size: 15px;
        line-height: 20px;
    }

    /* Ensure the container's position is relative */
    .image-left.container {
        position: relative;
    }

    .image-left, .image-right {
        margin: 1em 0;
    }

    @media (min-width: 20em) {
        .image-left, .image-right {
            display: flex;
            align-items: flex-start;
        }

        .image-left video, .image-left .fallback-image {
            margin-right: 1.5em;
            float: left; /* fallback */
        }

        .image-right video, .image-right .fallback-image {
            order: 1;
            margin-left: 1.5em;
            float: right; /* fallback */
        }
        
        /* clearfix for fallback */
        .image-left::after,
        .image-right::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    @media (min-width: 30em) {
        .image-left video, .image-left .fallback-image, .image-right video, .image-right .fallback-image {
            flex-shrink: 0;
        }
    }

    @media (max-width: 768px) {
        .welcome-text h1 {
            font-size: 1.8em;
        }

        .welcome-text h2 {
            font-size: 1.05em;
        }
        .description-content {
            margin: 15%;
            font-size: 4vw;
            line-height: 6vw;
        }

        .description-content p {
            font-size: 2.3vw;
        }
    }

    @media (min-width: 615px) {
        .welcome-text h1 {
            font-size: 3.3em;
        }

        .welcome-text h2 {
            font-size: 1.63em;
        }
    }

    .welcome-text {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Distribute space evenly between children */
        height: 100%; /* Ensure it takes up the full height of the container */
    }

    .welcome-text h1 {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        width: 100%;
        line-height: 1.2;
        position: relative;
        top: -0.1em; /* Shift up to remove space above */
        text-align: center;
        color: #616161;
    }

    .welcome-text h2 {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        line-height: 1.4;
        position: relative;
        top: 0.2em; /* Shift down to remove space below */
        text-align: left; /* Initially set to normal align */
        text-align-last: left; /* Initially set to normal align */
        -moz-text-align-last: left; /* Firefox compatibility */
        color: white; /* Start with white text */
    }

    #welcomeQuote{
    text-align: center;
    font-size: 1em;
    color: #a9a9a9;
    }

    .typed {
        opacity: 0;
        animation: fadeIn 2s forwards; /* Adjust the duration as needed */
        transition: color 2s;
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    /* CSS for shaking effect */
    @keyframes rotate-shake {
        0% { transform: rotate(0deg); }
        25% { transform: rotate(var(--shake-angle)); }
        50% { transform: rotate(calc(var(--shake-angle) * -1)); }
        75% { transform: rotate(var(--shake-angle)); }
        100% { transform: rotate(0deg); }
    }

    .shake {
        animation: rotate-shake var(--shake-duration) infinite;
    }

    @keyframes flip {
        0% {
            transform: perspective(800px) rotateY(0deg);
        }
        100% {
            transform: perspective(800px) rotateY(90deg);
        }
    }

    @keyframes flip2 {
        0% {
            transform: perspective(800px) rotateY(270deg);
        }
        100% {
            transform: perspective(800px) rotateY(360deg);
        }
    }

    .flip {
        animation: flip 1s ease-in-out forwards;
    }

    .flip2 {
        animation: flip2 1s ease-in-out forwards;
    }

    #compassIcon {
        --shake-angle: 10deg; /* Initial shake angle */
        --shake-duration: 0.5s; /* Initial shake duration */
    }

    .tipcolor-1 {
        color: #c9c9c9;
        transition: color 0.8s;
        font-size: 13px;
    }
    .tipcolor-2 {
        color: #ffffff;
        transition: color 0.8s;
        font-size: 13px;
    }

    #overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0); /* Transparent */
        z-index: 1000; /* High z-index to cover everything */
        pointer-events: all;
    }
</style>