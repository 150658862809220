<template>
  <div>
    <h1>
      My Learning Journey So Far
    </h1>
    <article>
      <p>Here’s a snapshot of my academic path and the experiences that have shaped my learning.</p>
      <p>I wouldn't say I'm an extremely hardworking student; in fact, I often look for shortcuts. But when it comes to things I'm passionate about, I dive in deep and never take shortcuts. I love immersing myself in what I care about and put in every effort to excel. Take my computer science courses, for instance—I spent a lot of time exploring interesting concepts beyond the textbook.</p>
      <div id="timeline">
        <div>
          <section class="year">
            <h3>BASc</h3>
            <section>
              <h4>September 2022 - Present</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hku.jpeg" style="height:8.5vh;">
                  </div>
                <li class="title">The University of Hong Kong</li>
                <li class="role">Bachelor of Arts and Sciences in Applied Artificial Intelligence</li>
                <li class="description">I'm a third-year student at <a href="https://www.hku.hk/">the University of Hong Kong</a>, majoring in <a href="https://admissions.hku.hk/programmes/undergraduate-programmes/bachelor-of-arts-and-sciences-applied-artificial-intelligence">Applied Artificial Intelligence</a>.</li>
                <li class="description">I have taken a diverse range of courses including Python, C++, Java, Data Structures and Algorithms, Software Engineering, Multivariable Calculus, Linear Algebra, Probability and Statistics, Machine Learning, Deep Learning, and Data Science.</li>
                <li class="description">Beyond my coursework, I served as a Student Representative on the CAES Staff-Student Consultative Committee and am currently a Student Peer Advisor (SPA), offering advice and helping freshmen and sophomores make study and career plans.</li>
              </ul>
            </section>
            <section>
              <h4>February 2025 - June 2025</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/umelb.jpg" style="height:7.5vh;">
                  </div>
                <li class="title">The University of Melbourne</li>
                <li class="role">Semester Exchange</li>
                <li class="description">Expected semester exchange at <a href="https://www.unimelb.edu.au/">The University of Melbourne</a> through <a href="https://intlaffairs.hku.hk/hku-worldwide-student-exchange">HKU Worldwide Student Exchange</a> program.</li>
                <li class="description">Courses Selected: System Optimisation, Machine Learning, Linear Statistical Models, Principles of Finance</li>
              </ul>
            </section>
            <section>
              <h4>September 2024 - December 2024</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/tsinghua.png" style="height:7.5vh;">
                  </div>
                <li class="title">Tsinghua University</li>
                <li class="role">Virtual Semester Exchange</li>
                <li class="description">Virtual semester exchange at <a href="https://www.tsinghua.edu.cn/en/">Tsinghua University</a> through <a href="https://vse.apru.org/">APRU Virtual Student Exchange</a> program.</li>
                <li class="description">Course Taking: Logic, Computation and Games by Professor Johan van Benthem</li>
              </ul>
            </section>
          </section>
          <section class="year">
            <h3>IBDP</h3>
            <section>
              <h4>September 2017 - June 2022</h4>
              <ul>
                  <div class="image-wrapper">
                      <img src="/assets/img/shsid.png" style="height:7.5vh;">
                  </div>
                <li class="title">Shanghai High School International Division</li>
                <li class="role">International Baccalaureate</li>
                <li class="description">I completed my junior high, high school, and International Baccalaureate diplomas at <a href="https://www.shsid.org/">Shanghai High School International Division</a>.</li>
                <li class="description">For my IB diploma, I studied a broad array of subjects including computer science, mathematics, economics, physics, English, and Chinese.</li>
                <li class="description">In addition to my coursework, I co-founded SHSID Musix Board, a school-wide popular music opinion-sharing platform, and led the Coding Club.</li>
              </ul>
            </section>
          </section>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'EducationTab'
}
</script>

<style scoped>
    @import url('../assets/css/timeline.css');
</style>

<!-- ## Shibei Junior High School

<img src="/assets/img/shibei.png" style="height:8vh;">

Prior to that, I attended the Science Class at [Shibei Junior High School](http://www.sbc.edu.sh.cn/), known as the best junior high school class for STEM subjects in Shanghai.
 -->