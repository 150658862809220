<template>
    <div>
      <h1>
        Website Traffic
      </h1>
      <article>
        <!-- <p>An interactive report created with Google Looker Studio.</p> -->
        <br>
        <div class='trafficReport'></div>
      </article>
    </div>
  </template>

<script>
export default {
  name: 'TrafficTab',
  data() {
      return {
          scriptModule: null,
      };
  },
  async mounted() {
      // Dynamically import the script when the component is mounted
      this.scriptModule = await import('../assets/js/loadtrafficreport.js');
      if (this.scriptModule && this.scriptModule.initScript) {
          this.scriptModule.initScript();
      }
  },
  unmounted() {
      // Clean up the script when the component is unmounted
      if (this.scriptModule && this.scriptModule.cleanupScript) {
          this.scriptModule.cleanupScript();
      }
      this.scriptModule = null; // Optionally, clear the reference to the module
  },
}
</script>