<template>
  <div>
    <h1>
      My Work Experience
    </h1>
    <article>
      <p>I've taken on various roles that have shaped who I am today. Come along as I share these key experiences with you!</p>
      <div id="timeline">
        <div>
          <section class="year">
            <h3>2024</h3>
            <section>
              <h4>August 2024 - December 2024</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hsbc.png" style="height:5.5vh;">
                  </div>
                <li class="title">Digital Transformation Intern</li>
                <li class="role">Hongkong and Shanghai Banking Corporation Limited</li>
                <li class="description">I'm participating in the development of strategic plans for the digital transformation of personal and private banking product sales, focusing on enhancing customer experience and streamlining processes.</li>
              </ul>
            </section>
            <section>
              <h4>July 2024 - April 2025</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hku.jpeg" style="height:8.5vh;">
                  </div>
                <li class="title">Part-time Student Research Assistant</li>
                <li class="role">Psychometrics and Data Science Lab, HKU</li>
                <li class="description">Under the supervision of Prof. Jinsong Chen, I'm exploring the intersection of Generative AI and personalized learning in the <a href="https://psym-ds.github.io/">PDS Lab</a>.</li>
                <li class="description">My work involves conducting literature reviews on contextual embeddings with transformer-based LLMs and developing a website for the PDS lab.</li>
                <li class="description">Here is the link to the website I developed: <a href="https://psym-ds.github.io/">PDS Laboratory</a>.</li>
              </ul>
            </section>
            <section>
              <h4>May 2024 - December 2024</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/hku.jpeg" style="height:8.5vh;">
                  </div>
                <li class="title">Part-time Student Research Assistant</li>
                <li class="role">Speech, Language, and Reading Lab, HKU</li>
                <li class="description">Under the supervision of Dr. Man-kit Lee, I am investigating human memory systems and learning behaviors in the <a href="https://slrlab.edu.hku.hk/">SLR Lab</a>.</li>
                <li class="description">My work involves developing a computational model to simulate these processes, alongside conducting literature reviews on artificial neural networks, reinforcement learning, and cognitive neuroscience. (Details are confidential)</li>
              </ul>
            </section>          
          </section>
          <section class="year">
            <h3>2023</h3>
            <section>
              <h4>June 2023 - December 2023</h4>
              <ul>
                  <div class="image-wrapper">
                    <img src="/assets/img/kiwiview.png" style="height:9vh;">
                  </div>
                <li class="title">Web Developer and Data Analyst</li>
                <li class="role">Kiwiview International Limited</li>
                <li class="description">I had the opportunity to work with <a href="https://www.kiwiviewintl.co.nz/uk-en/index.php">Kiwiview Intl.</a>, a New Zealand company that provides educational services for international study and customized travel solutions. This company is actually a family endeavor that my parents and I established together from the start.</li>
                <li class="description">I led the design, development, and hosting of their website using HTML, JavaScript, CSS, PHP, and MySQL. I also gathered, analyzed, and visualized global study and travel trends using various Python packages. (Details are confidential)</li>
                <li class="description">Here is the link to the website I developed: <a href="https://www.kiwiviewintl.co.nz/uk-en/index.php">Kiwiview International Limited</a>.</li>
              </ul>
            </section>
          </section>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ExperienceTab'
}
</script>

<style scoped>
    @import url('../assets/css/timeline.css');
</style>